<template>
  <div>
    <LoadingSpinner class="mt-12" v-if="!errorMsg" />
    <v-alert type="error" v-if="errorMsg" outlined class="ma-8">
      {{ errorMsg }}
      <div class="mt-6">
        <ContactSupportInfo />
      </div>
    </v-alert>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/misc/LoadingSpinner";
import azureSSO from "@/api/v24/azureSSO";
import user from "@/util/user";
import ContactSupportInfo from "@/components/misc/ContactSupportInfo";

export default {
  name: "SsoMicrosoftLoggedIn",
  components: { ContactSupportInfo },

  data: () => ({
    errorMsg: ""
  }),

  created() {
    const inParams = this.$route.query;
    const code = inParams.code;
    const sessionState = inParams.session_state;
    const stateInfo = inParams.state;
    if (!code || !sessionState) {
      this.showErrorMsg("Unable to parse out session data from V24 API");
      return;
    }

    azureSSO
      .getV24Session(code, sessionState)
      .then(res => {
        const sessionId = res.data.SessionId;
        if (!sessionId) {
          this.showErrorMsg("Unable to parse out session data from V24 API");
          return;
        }

        user.setIsSsoMsUser(sessionId, code).then(() => {
          user.goToStartPage();
        });
      })
      .catch(errTxt => {
        this.showErrorMsg(errTxt);
      });
  },

  methods: {
    showErrorMsg(text) {
      this.errorMsg = text;
    }
  }
};
</script>